import React from 'react'
import styled from 'styled-components'
import Default from '../components/Layouts/Default';

const docUrl = "https://docs.google.com/document/d/e/2PACX-1vQrhrwKwr0kPmhU8NZrE_nUAt9gyyeCq4Mta-LFbldAOx2HTguq5LJ_UEBLhcrwQj8N6FXZI_6Lij_D/pub?embedded=true";

const TermsFrame = styled.iframe`
  width: 100%;
  height: 100vh;
  width: 100%;
  border: none;
`

const PrivacyPage = () => <Default><TermsFrame src={docUrl} /></Default>


export default PrivacyPage;
